import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Template from "../../components/Template/Template"
import { renderTags } from "../../utilities/renderTags"
import Layout from "../../components/Layout/Layout"
import { LandingPage as LP } from "../../components/LandingPage"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"

export const query = graphql`
  query {
    template: contentfulTemplate(
      id: { eq: "f705c322-c470-5061-85a5-23dc66454507" }
    ) {
      ...templateFields
    }
    site {
      siteMetadata {
        defaultLocale
        siteUrl
      }
    }

    pageHeadMedia: file(
      relativePath: {
        eq: "landing-page/fr/page-head-section/page-head-media.svg"
      }
    ) {
      publicURL
    }
    stepsMedia: file(
      relativePath: { eq: "landing-page/fr/steps-section/steps-new-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    card1Media: file(
      relativePath: { eq: "landing-page/fr/cards-section/card-1-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 250)
      }
    }
    card2Media: file(
      relativePath: { eq: "landing-page/fr/cards-section/card-2-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 250)
      }
    }
    card3Media: file(
      relativePath: { eq: "landing-page/fr/cards-section/card-3-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 250)
      }
    }
    card4Media: file(
      relativePath: { eq: "landing-page/fr/cards-section/card-4-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 250)
      }
    }
    productMedia: file(
      relativePath: { eq: "landing-page/fr/cards-section/product-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    discoverLeftColMedia: file(
      relativePath: {
        eq: "landing-page/fr/discover-section/left-col-media.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    discoverRightColMedia: file(
      relativePath: {
        eq: "landing-page/fr/discover-section/right-col-media.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    tab1Media: file(
      relativePath: { eq: "landing-page/fr/tabs-section/tab-1-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    tab2Media: file(
      relativePath: { eq: "landing-page/fr/tabs-section/tab-2-media.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    getStartedMedia: file(
      relativePath: {
        eq: "landing-page/fr/get-started-section/get-started-media.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    youtubePreviewMedia: file(
      relativePath: { eq: "landing-page/fr/youtube-section/preview-image.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    youtubeAuthorThumb: file(
      relativePath: { eq: "landing-page/fr/youtube-section/author-thumb.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

const LandingPage = props => {
  useScrollTracking()
  usePageTracking()

  const {
    template,
    site: {
      siteMetadata: { defaultLocale }
    },
    pageHeadMedia: { publicURL: pageHeadMediaURL },
    stepsMedia: {
      childImageSharp: { gatsbyImageData: stepsMediaURL }
    },
    card1Media: {
      childImageSharp: { gatsbyImageData: card1MediaURL }
    },
    card2Media: {
      childImageSharp: { gatsbyImageData: card2MediaURL }
    },
    card3Media: {
      childImageSharp: { gatsbyImageData: card3MediaURL }
    },
    card4Media: {
      childImageSharp: { gatsbyImageData: card4MediaURL }
    },
    productMedia: {
      childImageSharp: { gatsbyImageData: productMediaURL }
    },
    tab1Media: {
      childImageSharp: { gatsbyImageData: tab1MediaURL }
    },
    tab2Media: {
      childImageSharp: { gatsbyImageData: tab2MediaURL }
    },
    discoverLeftColMedia: {
      childImageSharp: { gatsbyImageData: discoverLeftColMediaURL }
    },
    discoverRightColMedia: {
      childImageSharp: { gatsbyImageData: discoverRightColMediaURL }
    },
    getStartedMedia: {
      childImageSharp: { gatsbyImageData: getStartedMediaURL }
    },
    youtubePreviewMedia: {
      childImageSharp: { gatsbyImageData: youtubePreviewMediaURL }
    },
    youtubeAuthorThumb: {
      childImageSharp: { gatsbyImageData: youtubeAuthorThumbURL }
    }
  } = props.data
  const locale = "fr-FR"
  const trackingData = getPageData(
    locale,
    {
      pagePerimeter: "landing_page",
      pagePublication: "",
      pageFeature: "account_payable"
    },
    "lp-payer-vos-factures",
    props.path
  )

  const pageData = {
    pageHeadFormPortalID: "2856475",
    pageHeadFormID: "628f8d20-32ba-4139-9be3-0044d3142a0b",

    discoverFormPortalID: "2856475",
    discoverFormID: "77814770-b87f-47c0-ac8b-a50bd0f4d769",
    discoverMeetingCalendarUrl:
      "https://meetings.hubspot.com/salome-blondeau?embed-true",
    cta: "/fr/lp-payer-vos-factures/#discover-hs-form"
  }
  const mediaURLs = {
    pageHeadMedia: pageHeadMediaURL,
    stepsMedia: stepsMediaURL,
    card1Media: card1MediaURL,
    card2Media: card2MediaURL,
    card3Media: card3MediaURL,
    card4Media: card4MediaURL,
    productMedia: productMediaURL,
    tab1Media: tab1MediaURL,
    tab2Media: tab2MediaURL,
    discoverLeftColMedia: discoverLeftColMediaURL,
    discoverRightColMedia: discoverRightColMediaURL,
    youtubePreviewMedia: youtubePreviewMediaURL,
    youtubeAuthorThumb: youtubeAuthorThumbURL,
    getStartedMedia: getStartedMediaURL
  }
  return (
    <Layout>
      <Helmet>
        <title>
          Payez vos factures et gérez votre trésorerie dans le même outil
        </title>
        <script type="text/javascript">{`${trackingData}`}</script>
        {template &&
          template.headTags &&
          renderTags(locale, defaultLocale, template.headTags)}
      </Helmet>
      <Template {...template} defaultLocale={defaultLocale} locale={locale}>
        <LP
          locale={locale}
          defaultLocale={defaultLocale}
          pageData={pageData}
          media={mediaURLs}
        />
      </Template>
    </Layout>
  )
}

LandingPage.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

export default LandingPage
